<template>
    <div class="full-height color-txt">
        <s-form @submit="addItem">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-primary fs-lg-4 font-poppins-semibold">Deworming</h3>
                </div>
                <div class="col-lg-2 mt-lg-5">
                    <validated-ajax-vue-select class="c-input-select input-border-primary field-required" placeholder="Shed"
                                               v-model="shed_number" @input="loadCowOptions" label="Shed"
                                               :url="shedOptions" :rules="{required:true}"></validated-ajax-vue-select>
                </div>
            </div>
            <p class="fs-lg-2 text-primary font-poppins-semibold"> Enter Individually</p>
            <div class="row">
                <div class="col-lg-2">
                    <validated-vue-select :options="cowOptions" @input="loadDetails" class="c-input-select field-required"
                                          label="Animal No / Name" v-model="details.animalno_or_name" :rules="{required:true}"></validated-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Renumber" v-model="details.renumber" disabled></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Shed No" v-model="details.shed_number" disabled></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Breed" v-model="details.breed" disabled></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-date-picker format="DD-MM-YYYY" class="c-input-datepicker" label="Date of Birth" v-model="details.dob" disabled></validated-date-picker>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Age" v-model="model.age" disabled></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Cow Weight" v-model="model.cowweight" :rules="rules.positiveOnly" type="number" ></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Medicine Dose" v-model="model.medicineqty"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Medicine Name" v-model="model.medicine"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Medicine Batch No" v-model="model.batchno"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-date-picker format="DD-MM-YYYY" class="c-input-datepicker field-required" label="Deworming Date" v-model="model.dewormdate" :rules="rules.common"></validated-date-picker>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Remarks" v-model="model.remarks"></validated-input>
                </div>
            </div>
            <div class="row mt-lg-9">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                        <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                        <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                        </div>
                    </div>
                </div>
            </div>
        </s-form>
           <!-- <div class="row">
            <div class="col-lg-12" v-if="shedType === 's4'">
                <cows-deworming-second-page></cows-deworming-second-page>
            </div>
            <div class="col-12" v-else-if="shedType === 's9'">
                <cows-deworming-third-page></cows-deworming-third-page>
            </div>

        </div>-->

    </div>
</template>

<script>
// import CowsDewormingThirdPage from '@/views/cows/deworming/CowsDewormingThirdPage';
// import CowsDewormingSecondPage from '@/views/cows/deworming/CowsDewormingSecondPage';
import urls from '../../../data/urls';
import masterURLs from '../../../data/masterURLs';
import axios from 'secure-axios';
export default {
    name: 'CowsDewormingFirstPage',
    // components: { CowsDewormingSecondPage, CowsDewormingThirdPage },
    data () {
        return {
            shed_number: null,
            URL: urls.cowDeworming.addEdit,
            loading: false,
            shedOptions: masterURLs.master.shed.vueSelect + '?animal=cow',
            cowOptions: [],
            details: {
                renumber: '',
                breed: '',
                dob: '',
                shed_number: '',
                animalno_or_name: ''

            },
            model: {
                cow: '',
                age: '',
                cowweight: '',
                medicineqty: '',
                medicine: '',
                batchno: '',
                dewormdate: '',
                remarks: ''
            },
            rules: {
                positiveOnly: {
                    // required: true,
                    customValidator: (value) => {
                        return this.avoidNegetive(value);
                    }

                },
                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.Validation(value);
                    }
                }
            }
        };
    },
    methods: {
        async loadDetails () {
            const response = await axios.form(urls.cows.details, { id: this.details.animalno_or_name });
            const data = response.data.data;
            this.model.cow = data.id;
            this.model.age = data.age;
            this.details.renumber = data.renumber;
            this.details.breed = data.breed.breed_name;
            this.details.dob = data.dob;
            this.details.shed_number = data.shedno.name;
        },
        Validation (date) {
            const dob = this.details.dob;
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            const dobParts = dob.split('-');
            if (dobParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const dobDay = parseInt(dobParts[0], 10);
            const dobMonth = parseInt(dobParts[1], 10);
            const dobYear = parseInt(dobParts[2], 10);

            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(dobDay) || isNaN(dobMonth) || isNaN(dobYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const dobDateObj = new Date(dobYear, dobMonth - 1, dobDay);

            if (dateObj <= dobDateObj) {
                return 'Date should be after the date of birth (DOB).';
            }
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (dateObj > currentDate) {
                return 'Future Date not allowed.';
            }

            return true;
        },
        BackToHome () {
            this.$router.push({ path: '/ah-section/' });
        },
        async loadCowOptions () {
            this.dataclear();
            const response = await axios.get(urls.cows.vueSelect + '?shed_number=' + this.shed_number);
            this.cowOptions = response.data.data;
        },

        dataclear () {
            this.model.age = '';
            this.model.cow = '';
            this.details.dob = '';
            this.details.renumber = '';
            this.details.breed = '';
            this.details.shed_number = '';
            this.details.animalno_or_name = '';
        },
        avoidNegetive (endDate) {
            const num = endDate;
            if (num <= 0) {
                return 'Please enter a valid number';
            }
            return true;
        },

        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/cows-list/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    },
    computed: {
        shedType () {
            return this.models.shedType;
        }
    }
};
</script>

<style scoped>

</style>
